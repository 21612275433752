<template>
  <div class="container-fluid" id="jobs-container">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
            my-schedule
            mb-2
          "
        >
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="font-weight-bold">My Jobs</h4>
          </div>
          <div class="create-workform">
            <div
              class="
                d-flex
                flex-wrap
                align-items-center
                justify-content-between
              "
            >
              <div class="d-flex align-items-center display-search">
                <div class="mr-2 search-input">
                  <search
                    :search="search"
                    :placeholder="'Search'"
                    @searchFilter="handleSearchJobList"
                  >
                  </search>
                  <p class="search-css">
                    Search by order number, customer phone, email
                  </p>
                </div>                
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-block card-stretch">
              <div class="card-body-2">
                <div
                  class="
                    mb-2
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-between
                  "
                >
                  <h5>Jobs List
                    <button
                          class="ml-2
                            btn btn-success btn-sm"
                          @click="refreshClick()"
                          
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 24 24">
                          <path d="M20.944 12.979c-.489 4.509-4.306 8.021-8.944 8.021-2.698 0-5.112-1.194-6.763-3.075l1.245-1.633c1.283 1.645 3.276 2.708 5.518 2.708 3.526 0 6.444-2.624 6.923-6.021h-2.923l4-5.25 4 5.25h-3.056zm-15.864-1.979c.487-3.387 3.4-6 6.92-6 2.237 0 4.228 1.059 5.51 2.698l1.244-1.632c-1.65-1.876-4.061-3.066-6.754-3.066-4.632 0-8.443 3.501-8.941 8h-3.059l4 5.25 4-5.25h-2.92z"/>
                        </svg>
                        Refresh
                    </button>
                  </h5>
                  <div class="d-flex flex-wrap mt-2 mobile-align-customer">

                    <div class="d-flex">
                      <div class="ml-2 filter-div">
                        <button
                          class="
                            btn btn-primary btn-sm
                            d-flex
                            align-items-center
                          "
                           @click="filterClick()"
                          v-on:click="showFilters = !showFilters"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="mr-1"
                            width="15"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                          <div class="notify">filters
                            <div class="badge"  v-if="countFilter">
                              <div class="message-count">{{countFilter}}</div>
                            </div>
                          </div>
                        </button>
                      </div>

                      <div class="ml-2">
                        <button
                          class="
                            btn btn-warning btn-sm
                            d-flex
                            align-items-center
                            reset-btn-padding
                          "
                          @click="resetFilters"
                          title="Reset Filters"
                        >
                          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="15" height="15" viewBox="0 0 1280.000000 1266.000000"
                            preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,1266.000000) scale(0.100000,-0.100000)"
                            fill="white" stroke="none">
                            <path d="M6130 12653 c-1136 -47 -2253 -397 -3200 -1002 -1163 -744 -2048
                            -1830 -2525 -3096 -433 -1150 -520 -2406 -249 -3595 169 -740 463 -1430 880
                            -2063 765 -1161 1874 -2034 3189 -2509 977 -354 2044 -467 3080 -328 1388 186
                            2694 829 3675 1810 129 129 1201 1318 1207 1339 2 8 -1951 1746 -1964 1746 -5
                            0 -200 -214 -433 -475 -596 -668 -655 -732 -782 -847 -599 -539 -1284 -857
                            -2083 -964 -292 -39 -726 -36 -1025 7 -1069 153 -1996 719 -2605 1589 -746
                            1066 -863 2457 -305 3632 359 755 979 1375 1745 1744 387 186 728 287 1180
                            351 213 29 712 33 927 5 649 -82 1206 -288 1723 -638 269 -182 481 -372 781
                            -704 153 -168 162 -181 146 -196 -9 -9 -287 -254 -617 -544 -330 -291 -601
                            -532 -603 -537 -2 -4 4 -8 13 -8 8 0 305 -68 658 -150 353 -83 851 -200 1107
                            -260 256 -60 726 -170 1045 -245 319 -75 831 -195 1138 -267 307 -71 560 -128
                            562 -126 3 2 -47 960 -110 2129 -63 1168 -119 2221 -126 2339 -6 118 -14 245
                            -17 282 l-7 67 -520 -459 c-286 -252 -522 -459 -525 -459 -3 -1 -66 67 -140
                            150 -194 219 -425 458 -575 595 -1250 1147 -2934 1759 -4645 1687z"/>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <transition name="slide-fade">
                  <div class="row d-flex pb-5 pr-5 pl-5" v-if="showFilters">
                    <div class="col-md-3 filter-tabs">
                      <label
                        for="car-make"
                        class="
                          form-label
                          text-muted
                          filter-label-heading
                          text-uppercase
                          mt-3
                        "
                      >Status</label>

                      <multiselect
                        v-model="jobStatus"
                        :options="jobStatusArray"
                        label="name"
                        track-by="id"
                        placeholder="Select"
                        :preserve-search="true" 
                        @select="onChangeJobsStatus"
                        @remove="removeStatus"
                        :class="{ singleOptions: true }"
                        :max="jobStatusArray.length"
                        :show-labels="false" 
                        :allow-empty="true"
                        
                      >
                      </multiselect>
                    </div>
                    
                   
                    <div class="col-md-2 d-flex">
                      <div class="row">
                        <div class="col">
                          <label
                            for="car-make"
                            class="
                              form-label
                              text-muted
                              filter-label-heading
                              text-uppercase
                              mt-3
                              white-space
                            "
                            >Is Active
                          </label>
                          <b-form-checkbox
                            id="is-active"
                            size="lg"
                            v-model="checkedIsActive"
                            @input="handleIsActive"
                            name="check-button"
                            switch
                            :class="{'borderColorFilter': isActive, 'is-true':true}"
                          >
                          </b-form-checkbox>
                        </div>
                        <div class="col">
                          <label
                            for="car-make"
                            class="
                              form-label
                              text-muted
                              filter-label-heading
                              text-uppercase
                              mt-3
                              white-space
                            "
                            >Is Completed
                          </label>
                          <b-form-checkbox
                            size="lg"
                            v-model="checkedIsCompleted"
                            @input="handleIsCompleted"
                            name="check-button"
                            switch
                            :class="{'borderColorFilter': isCompleted, 'is-true':true}"
                          >
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <div id="load-spinner">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div class="table-responsive table-zoom" id="jobs-table">
                  <table class="table data-table mb-0 table-customer">
                    <thead class="table-color-heading">
                      <tr class="">
                        <!-- <th scope="col">View</th> -->
                        <th scope="col">Type</th>
                        <th scope="col">Order</th>
                        <th scope="col">Car</th>
                        <th scope="col">Status</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Scheduled At</th>
                        <th scope="col">View Order</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="white-space-no-wrap transition-row"
                        v-for="jobs in myJobs.edges"
                        :key="jobs.node.id"
                      >
                        <td class="table-font">
                          <div class="data-content">
                            {{
                              jobs.node.type | customTitleCase
                            }}
                          </div>
                        </td>
                        <td class="table-font" v-if="!!jobs.node.order">
                          <div title="Click To Copy">
                            <div
                              @click.prevent="
                                copyIdToClipboard(jobs.node.order.number)
                              "
                              class="customer-order"
                            >
                              {{ jobs.node.order.number}}
                            </div>
                          </div>
                          {{' ('}}{{ jobs.node.order.status | customTitleCase }}{{')'}}
                        </td>
                         <td v-else>-</td>
                        <td class="table-font">
                          {{
                            jobs.node.order.car.model.make.name
                          }} <br/> {{
                            jobs.node.order.car.model.name
                          }} <br/> {{
                            jobs.node.order.car.licensePlate
                          }}
                        </td>
                        <td
                          v-if="jobs.node.status"                          
                        >
                          {{ jobs.node.status | customTitleCase }}
                        </td>
                        <td class="table-font" v-if="jobs.node.updatedAt" :title="jobs.node.updatedAt">
                            {{  jobs.node.updatedAt | DateTimeFormat }}
                        </td>
                        <td v-else>-</td>
                        <td class="table-font" v-if="jobs.node.scheduledAt" :title="jobs.node.scheduledAt">
                            {{  jobs.node.scheduledAt | DateTimeFormat }}
                        </td>
                        <td v-else>-</td>
                        <td class="table-font">
                          <div class="hide-data-list" :id="'order-div-id-'+jobs.node.order.number">
                          {{
                              jobs.node.order.number
                          }}
                          </div>
                          <button
                            class="btn btn-sm mr-1"
                            @click="handleOrderViewLink('order-div-id-'+jobs.node.order.number)"
                          >
                           <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                />
                              </svg>
                           
                          </button>
                        </td>
                      </tr>
                      <tr v-if="totalRows === 0">
                        <td colspan="8" align="center">No data found</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="p-3 row" id="page-bottom">
                  <div class="col-sm-12 col-md-6 d-flex align-items-center">
                    <div>
                      Total Jobs: <b>{{ totalRows }}</b>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 d-flex justify-content-end">
                    <pagination
                      :currentPage="currentPage"
                      :perPage="perPage"
                      :totalRows="totalRows"
                      @changePage="handlePageChange"
                    ></pagination>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import search from "../../../components/filters/search.vue";
import pagination from "../../../components/pagination/pagination.vue";
import { MY_JOBS } from "../../../graphql/query";
import "@fortawesome/fontawesome-free/css/all.css";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
  components: { pagination, search },
  name: "customer",
  data() {
    return {
      checkedIsCompleted: false,
      checkedIsActive: false,
      showFilters: false,
      myJobs: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      after: null,
      before: null,
      hasNextPage: true,
      hasPreviousPage: false,
      last: null,
      first: null,
      search: null,
      searchText: null,
      isCompleted: null,
      isActive: null,
      status: null,
      statusData: null,
      countFilter:0,

      jobStatus: null,
      jobStatusId: null,
      jobStatusArray: [
        {id: "UNDECIDED", name:"Undecided"},
        {id: "CANCELLED", name:"Cancelled"},
        {id: "COMPLETED", name:"Completed"},
      ],
      jobStatusData: [],

      jobsFilters: {
        status: null,
        isCompleted:null,
        isActive:null,
        jobStatusData: null
      }
    };
  },
  mounted() {
    this.first = this.perPage;
    if(localStorage.getItem("jobsFilters") !== null){
      this.getLocalStorage();
      this.getJobsList();
    }
    else{
      localStorage.setItem('jobsFilters',JSON.stringify(this.jobsFilters))
      this.getLocalStorage();
      this.getJobsList();
    }
  },
  methods: {
      filterClick(){
        this.countFilter = document.querySelectorAll('.borderColorFilter').length;
        if(this.jobsFilters.isActive === true){
          this.isActive = this.jobsFilters.isActive;
          this.checkedIsActive = this.jobsFilters.isActive;
        }
      },
      getLocalStorage(){
       this.jobsFilters = JSON.parse(localStorage.getItem("jobsFilters"));
        if(
            this.jobsFilters.jobStatusData !== null ||
            this.jobsFilters.isActive !== null ||
            this.jobsFilters.isCompleted !== null) {

            this.showFilters = true;            

            if(this.jobsFilters.isCompleted){
              this.isCompleted = this.jobsFilters.isCompleted;
              this.checkedIsCompleted = this.jobsFilters.isCompleted;
              this.countFilter += 1;
            }

            if(this.jobsFilters.isActive === true){
              this.isActive = this.jobsFilters.isActive;
              this.checkedIsActive = this.jobsFilters.isActive;
              this.countFilter += 1;
            }
            else{
              this.isActive = this.jobsFilters.isActive;
              this.checkedIsActive = this.jobsFilters.isActive;
            }

            if(this.jobsFilters.jobStatusData){
              this.jobStatusId = this.jobsFilters.jobStatusData;
              this.countFilter += 1;
            }
            console.log(this.countFilter, "countFilter")
        
            this.showFilters = false;
        }
        else{
          this.showFilters = false;
        }
      },

     refreshClick(){
      this.first = this.perPage;
      this.last = null;
      this.before = null;
      this.after = null;
      this.currentPage = 1;
      this.getJobsList();
    },

    async getJobsList() {
      document.getElementById('jobs-table').style.display = 'none';
      document.getElementById('load-spinner').style.display = 'flex';
      document.getElementById('page-bottom').style.display = 'none';
      await this.$apollo
        .query({
          query: MY_JOBS,
          variables: {
            first: this.first,
            after: this.after,
            before: this.before,
            last: this.last,
            search: this.search,
            isCompleted: this.isCompleted,
            isActive: this.isActive,
            type: ["WORKSHOP_SERVICE", "PARTS"],
            status: this.jobStatusId ? this.jobStatusId.id : null
          },
        })
        .then((data) => {
          document.getElementById('load-spinner').style.display = 'none';
          document.getElementById('jobs-table').style.display = 'block';
          document.getElementById('page-bottom').style.display = 'flex';
          this.myJobs = data.data.myJobs;
          this.totalRows = data.data.myJobs.totalCount;
          this.after = data.data.myJobs.pageInfo.endCursor;
          this.before = data.data.myJobs.pageInfo.startCursor;
          this.hasNextPage = data.data.myJobs.pageInfo.hasNextPage;
          this.hasPreviousPage = data.data.myJobs.pageInfo.hasPreviousPage;
        });
    },
    handlePageChange(event) {
      if (event > this.currentPage && this.hasNextPage) {
        this.currentPage = event;
        this.before = null;
        this.last = null;
        this.first = this.perPage;
        this.after;
        this.getJobsList();
      } else if (event < this.currentPage && this.hasPreviousPage) {
        this.currentPage = event;
        this.after = null;
        this.before;
        this.last = this.perPage;
        this.first = null;
        this.getJobsList();
      }
    },
    handleSearchJobList(event) {
      this.search = event;
      this.after = null;
      this.before = null;
      this.last = null;

      this.first = this.perPage;
      this.currentPage = 1;

      this.getJobsList();
    },

    onChangeJobsStatus(event) {
      this.jobStatusId = event;
      this.jobsFilters.jobStatusData = event;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      localStorage.setItem('jobsFilters',JSON.stringify(this.jobsFilters));
      this.getJobsList();
      this.jobStatus = null;
    },

    removeStatus() {
      this.jobStatusId = null;
      this.jobsFilters.jobStatusData = null;     
      localStorage.setItem('jobsFilters',JSON.stringify(this.jobsFilters));
      this.before = null;
      this.after = null;
      this.currentPage = 1;
      this.getJobsList();
    }, 
    handleIsActive() {
      this.isActive = this.checkedIsActive;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.jobsFilters.isActive = this.checkedIsActive;
      localStorage.setItem('jobsFilters',JSON.stringify(this.jobsFilters));
      this.getJobsList();
    },
    handleIsCompleted() {
      this.isCompleted = this.checkedIsCompleted;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.jobsFilters.isCompleted = this.checkedIsCompleted;
      localStorage.setItem('jobsFilters',JSON.stringify(this.jobsFilters));
      this.getJobsList();
    },

    resetFilters() {
      this.currentPage = 1;
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null,
      this.statusData = null, 
      this.jobStatusId = null,
      this.search = null,
      this.handleIsCompleted();
      this.handleIsActive();
      localStorage.removeItem("jobsFilters");
      this.countFilter = 0;
      this.getJobsList().then(() => {
        location.reload();
      });
    },

    handleJobListLocation(id) {
      var location = document.getElementById(id).innerHTML;
      location = location.replace(/\s+/g, '');
      let url = 'https://www.google.com/maps/search/?api=1&query='+location;
      window.open(url, "_blank");
    },

    handleOrderViewLink(id){
      var order = document.getElementById(id).innerHTML;
      order = order.replace(/\s+/g, '');
      let url = 'order/'+order;
      window.open(url, "_blank");
    },
    
    copyIdToClipboard(id) {
      // Create a temporary input element
      var input = document.createElement('textarea');
      input.value = id.trim(); // Remove leading/trailing spaces
      document.body.appendChild(input);

      // Select the text within the input
      input.select();
      input.setSelectionRange(0, 99999); // For mobile devices

      // Copy the selected text to the clipboard
      document.execCommand('copy');

      // Clean up by removing the input element
      document.body.removeChild(input);

      var message = "Copied";
      this.$bvToast.toast(id + " " + `${message}`, {
        toaster: "b-toaster-top-center",
        variant: "success",
        solid: true,
        toastClass: "text-center toast-box toast-style",
      });
    },
  },
};
</script>

<style>
.filter-btn {
  font-size: 1.1rem !important;
}
.display-search .search-input {
  margin-top: 45px;
}
.search-css {
  color: #757981;
  font-size: small;
  padding: 5px;
}
table.table-customer {
  text-align: center;
}

.transition-row {
  transition: transform 0.1s;
}
.transition-row:hover {
  background-color: rgb(245, 245, 245);
  /* transform: scale(0.99); */
}
.click-area-extend {
  padding: 0.25rem 1rem 0.25rem 1rem;
  cursor: pointer;
}
.toast-style {
  font-size: 1.2rem;
  padding: 1rem;
}

.b-toast-success.b-toast-solid .toast {
  padding: 10px;
  width: 200px;
  margin: 0 auto;
}
.customer-order:hover {
  color: blue;
  cursor: pointer;
}
.text-area-clipboard {
  border: none;
  color: white;
  height: 0;
  width: 0;
}
.table-zoom {
  zoom: 90%;
}
.white-space {
  overflow: auto;
  white-space: nowrap;
}

.card-body-2 {
  padding: 10px 20px 20px 20px;
}
.singleOptions .multiselect__tags {
  min-height: 10px !important;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 13px !important;
}

.multiselect__input,
.multiselect__single {
  min-height: 12px !important;
  line-height: 14px !important;
}

.multiselect__select {
  height: 31px !important;
}

.filter-cross-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0 0.75rem 0 0.75em;
  border-radius: 0.25rem;
}

.filter-label-heading {
    font-weight: 600;
    /* font-size: 0.9rem; */
    font-size: 12px;
    text-transform: capitalize !important;
}

.b-form-btn-label-control.form-control > .form-control.form-control-sm {
  overflow: auto !important;
  white-space: nowrap !important;
}
.reset-btn-padding {
  padding: 0.4rem;
}

.assign-btn-padding{
  padding: 0.2rem;
}

.assigned-to-custom{
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 40%;
    right: 20%;
    z-index: 1;
    transform: translate(0%, -50%);
}

#gte_datetime_picker.form-control, #lte_datetime_picker.form-control{
  height:32px !important;
  /* padding: 0.625rem 1rem !important; */
}

#jobs-container .singleOptions .multiselect__placeholder {
    color: #adadad;
    display: inline-block;
    padding-left: 5px;
    min-height: 12px !important;
    line-height: 12px !important;
    margin-bottom: 7px;
}

#jobs-container .multiselect {

    min-height: 32px !important;
    
}

#jobs-container .borderColorFilter{
  /* background-color: gray; */
  border: 2px solid #9a18ffd1 !important;
      border-radius: 5px;

}

#jobs-container .borderColorFilter.is-true{
  border: none !important;
}

#jobs-container .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #3378FF;
    border-color: #3378FF;
    border: 2px solid #9a18ffd1 !important;
    border-radius: 10px;
}

#jobs-container .filter-tabs.col-md-3 {
    flex: 0 0 20%;
    max-width: 20%;
}

.multOptions .multiselect__tags {
  /* height: 72px; */
  height: 55px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.notify .badge {
  background: radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
  background: -moz-radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
  background: -ms-radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
  background: -o-radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
  background: -webkit-radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
  background-color: #ed2324;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 5% 5%;
  float: right;
  display: block;
  position: relative;
  top: -16px;
  left: 12px;
}

.message-count {
  position: relative;
  display: block;
  font: bold 14px/13px Helvetica, Verdana, Tahoma;
  text-align: center;
  margin: 0;
  top: 2px;
}

.hide-data-list{
  display: none;
}

.customMultiSelect-div{
    max-width: 22.20%;
    flex: 0 0 22.20%;
}

#load-spinner{
justify-content: center;
display: none;
}
@media only screen and (max-width: 768px) {
  .table th,
  .table td {
    padding: 10px 5px;
  }
  .customMultiSelect-div{
    max-width:100%;
    flex: 0 0 100%;
  }

  #jobs-container .filter-tabs.col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}


/* @media only screen and (max-width: 470px) {
  .mobile-align-customer {
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }
} */

@media only screen and (max-width: 417px) {
  .sorting-div{
    margin-bottom: 1rem;
  }
  .filter-div{
    margin-left: 0rem !important;
  }
  .display-search.d-flex {
    display: block !important;
  }
  .display-search .btn {
    width: 50%;
  }
}
</style>

